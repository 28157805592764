<template>
  <v-container fluid v-if="profile">
    <v-card>
      <v-toolbar dense flat class="teal" dark>
        <v-toolbar-title>Agendamentos</v-toolbar-title>
      </v-toolbar>
      <v-card-title class="mb-n5 mt-n3 grey lighten-5" v-if="schedulings">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="800"
          right
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined v-bind="attrs" v-on="on">
              <v-badge dot overlap color="red" v-if="filter_enable">
                <v-icon>mdi-filter</v-icon>
              </v-badge>
              <v-icon v-else>mdi-filter</v-icon>
            </v-btn>
          </template>
          <filterScheduling
            v-on:update-table="updateTable"
            v-on:update="schedulingFilter()"
            v-on:update-badge="filter_badge"
            :menu="filter_scheduling"
          />
        </v-menu>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="schedulings"
        :headers="headers"
        :items="schedulings"
        class="elevation-1"
        show-expand
        :search="search"
      >
              <template v-for="header,i in headers" v-slot:[`header.${header.value}`]="{ header }" >         <!-- eslint-disable-line-->

          <span v-if="!header.in_expanded" :key="i">
            {{ header.in_expanded }} {{ header.text }}
          </span>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="row no-gutters">
              <v-list class="transparent" width="700">
                <v-list-item v-for="(i, index) in header_expanded" :key="index">
                  <v-list-item-content>
                    <strong>{{ i.text }}: </strong></v-list-item-content
                  >

                  <v-list-item-content v-if="i.value === 'data_scheduling'">
                    {{ item[i.value] | formatDate }}
                  </v-list-item-content>
                  <v-list-item-content
                    v-if="i.value === 'start_date' || i.value === 'end_date'"
                  >
                    {{ item[i.value] | formatDateTime }}
                  </v-list-item-content>

                  <v-list-item-content v-else>
                    {{ item[i.value] }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </td>
        </template>

        <template v-slot:item.protocol="{ item }">
          <v-btn text @click="getReports(item.protocol)"
            >{{ item.protocol }}
            <v-icon right color="primary">mdi-message-text-outline</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.priority="{ item }">
          <v-chip label small dark :color="priorityColor(item.priority)">
            <v-icon v-if="item.unavoidable" small left>mdi-alert-circle</v-icon>

            {{ item.priority }}
          </v-chip>
        </template>
        <template v-slot:item.period="{ item }">
          <v-chip :color="colorPeriod(item.period)" label small dark>{{
            item.period
          }}</v-chip>
        </template>
        <template v-slot:item.event_status="{ item }">
          <div v-if="!item.event_status">
            <v-chip>NÃO INICIADO</v-chip>
          </div>

          <div v-else>
            <strong
              v-if="item.event_status_id !== 2"
              :style="{ color: eventColor(item.event_status_id) }"
            >
              {{ item.event_status }}
            </strong>
            <div v-else>
              <strong :style="{ color: eventColor(item.event_status_id) }">
                {{ item.event_status }}
              </strong>
              <v-chip label color="grey lighten-5">
                <div>
                  Iniciado há
                  <span class="font-weight-medium"> {{ item.diff_time }}</span>
                </div>
              </v-chip>
            </div>
          </div>
        </template>
        <template v-slot:item.data_scheduling="{ item }">
          {{ item.data_scheduling | formatDate }}
        </template>
        <template v-slot:item.scheduling_status="{ item }">
          <div v-if="!item.scheduling_status">
            <v-chip>Não Iniciado</v-chip>
          </div>
          <div v-else>
            {{ item.scheduling_status }}
          </div>
        </template>
        <template v-slot:item.responsible_name="{ item }">
          <div v-if="!item.responsible_name">
            <v-chip>Não vinculado</v-chip>
          </div>
          <div v-else>
            {{ item.responsible_name }}
          </div>
        </template>
        <template v-slot:item.analyst_name="{ item }">
          <div v-if="!item.analyst_name">
            <v-chip>Não vinculado</v-chip>
          </div>
          <div v-else>
            {{ item.analyst_name }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div>
            <v-btn class="colorLink" icon :disabled="!canEdit">
              <v-icon @click="edit(item)">mdi-circle-edit-outline</v-icon>
            </v-btn>

            <v-btn color="error" icon class="mr-2" v-if="isAdmin || canRemove">
              <v-icon @click="remove(item)">mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <dialogReports
      v-if="active_dialog"
      v-on:close-dialog="active_dialog = !active_dialog"
      :dialog="active_dialog"
      :reports="reports"
    />
    <EditScheduling
      :user="profile"
      v-if="edit_dialog"
      :dialog="edit_dialog"
      v-on:update="schedulingFilter()"
      :edit="edit_form"
      v-on:close-dialog="edit_dialog = !edit_dialog"
    />
    <v-dialog v-model="removeDialog" max-width="500px" v-if="removeDialog">
      <removeScheduling
        :remove_scheduling="scheduling_remove"
        v-on:update="schedulingFilter()"
        v-on:close-dialog="removeDialog = !removeDialog"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import dateFormat from "../../mixins/formatDate";
import dialogReports from "./dialogReports";
import EditScheduling from "./edit";
import filterScheduling from "./filterScheduling";
import removeScheduling from "./remove";
import dayjs from "dayjs";
var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);
import Authz from "../../mixins/authorized";

export default {
  mixins: [dateFormat, Authz],
  components: {
    dialogReports,
    EditScheduling,
    filterScheduling,
    removeScheduling
  },

  data() {
    return {
      items: "",
      timer: null,
      profile: null,
      editDialog: false,
      filter_enable: false,
      removeDialog: false,
      schedulings: "",
      edit_dialog: false,
      scheduling_remove: {},
      search: null,
      filteredSchedulings: [],
      edit_form: null,
      menu: false,
      filter_scheduling: false,
      reports: "",
      active_dialog: false,
      headers: [
        { text: "Protocolo", value: "protocol" },
        { text: "Cliente", value: "client" },
        { text: "Cidade", value: "city" },
        { text: "Bairro", value: "district" },
        { text: "Data de Agendamento", value: "data_scheduling" },
        { text: "Período", value: "period" },

        { text: "Situação da OS", value: "event_status" },
        { text: "Serviço", value: "os_type" },
        { text: "Técnico", value: "responsible_name" },

        { text: "Prioridade", value: "priority" },
        { text: "Ações", value: "actions" }
      ],
      header_expanded: [
        { text: "Área", value: "area" },
        { text: "Serviço", value: "os_type" },

        { text: "Analista", value: "analyst_name" },
        { text: "Técnico", value: "responsible_name" },
        { text: "Iniciado em", value: "start_date" },
        { text: "Finalizado em", value: "end_date" }
      ]
    };
  },

  created() {
    this.getProfile();
    this.schedulingFilter();
  },
  mounted() {
    this.updateTime();
    this.$options.timer = setInterval(this.updateTime, 1000);
    // this.header_filters = Object.assign({}, this.header_expanded, this.headers)
  },
  methods: {
    async remove(scheduling) {
      this.removeDialog = true;
      this.scheduling_remove = scheduling;
    },
    async schedulingFilter() {
      if (localStorage.getItem("filters") !== null) {
        this.filter_enable = true;
        let criterias = JSON.parse(window.localStorage.getItem("filters"));
        const response = await this.$http.post("/scheduling/filter", criterias);
        this.schedulings = response.data;
      } else {
        this.getScheduling();
      }
    },
    filter_badge(val) {
      if (val) {
        return (this.filter_enable = true);
      }
      return (this.filter_enable = false);
    },
    updateTime() {
      let now = dayjs();
      Object.values(this.schedulings).forEach(row => {
        if (!row.end_date) {
          let diff = dayjs.duration(now.diff(row.start_date));
          if (diff.days() > 0) {
            this.$set(
              row,
              "diff_time",
              `${
                diff.format("DD") > 1
                  ? diff.format("DD") + " Dias,"
                  : diff.format("DD") + " Dia,"
              } ${diff.format("HH")}h ${diff.format("mm")}m ${diff.format(
                "ss"
              )}s`
            );
          } else {
            this.$set(
              row,
              "diff_time",
              `${diff.format("HH")}h ${diff.format("mm")}m ${diff.format(
                "ss"
              )}s`
            );
          }
        }
      });
    },
    async getProfile() {
      try {
        const response = await this.$http.get("/user/profile");
        this.profile = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updateTable(val) {
      this.schedulings = val;
    },
    async getReports(protocol) {
      try {
        const response = await this.$http.get(`/syn/reports/${protocol}`);

        this.reports = response.data;
        this.active_dialog = true;
      } catch (error) {
        if (error.response.status !== 501) {
          return this.$toast.error("Error ao consultar!");
        }
        this.$toast.error(
          "Não foi possivel consultar os relatos da solicitação!"
        );
      }
    },

    async edit(item) {
      this.edit_dialog = true;
      this.edit_form = item;
    },
    colorPeriod(value) {
      const colors = {
        Manhã: "primary",
        Tarde: "warning"
      };
      return colors[value];
    },
    priorityColor(value) {
      const colors = {
        ALTA: "red darken-1",
        MÉDIA: "orange darken-1",
        BAIXA: "green darken-1",
        INADIÁVEL: "grey darken-1"
      };
      return colors[value];
    },
    eventColor(value) {
      const colors = {
        1: "#619FFC",
        2: "#F49025",
        3: "#43BF57",
        4: "#30475e",
        5: "#30475e",
        6: "#30475e",
        7: "#30475e",
        8: "#30475e",
        9: "#30475e",
        10: "#F2451C",
        11: "#30475e"
      };
      return colors[value];
    },
    async getScheduling() {
      const response = await this.$http.get("/schedulings");
      this.schedulings = response.data;
    }
  }
};
</script>
<style>
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
