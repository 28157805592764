<template>
  <div>
    <v-dialog
      v-model="active"
      v-if="edit"
      persistent
      scrollable
      transition="dialog-transition"
      max-width="900"
    >
      <v-card :key="updated">
        <v-toolbar color="teal" dark flat>
          <v-toolbar-title> Agendamento - {{ form.protocol }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-tabs>
            <v-tab>
              <v-icon left> mdi-card-account-details-outline </v-icon>
              Identificação
            </v-tab>
            <v-tab>
              <v-icon left> mdi-cog-outline </v-icon>
              Editar ordem de serviço
            </v-tab>
            <v-tab>
              <v-icon left> mdi-calendar </v-icon>
              Editar Agendamento
            </v-tab>
            <v-tab-item class="mt-5">
              <v-list dense>
                <v-row v-for="(item, i) in headers" :key="i">
                  <v-col cols="12">
                    <v-list-item>
                      <v-list-item-content>
                        <strong> {{ item.value }}: </strong>
                      </v-list-item-content>
                      <v-list-item-content class="align-end">
                        <div v-if="form[item.name] === form.data_scheduling">
                          {{ form.data_scheduling | formatDate }}
                        </div>
                        <div v-else-if="form[item.name] === form.created_at">
                          {{ form.created_at | formatDateTime }}
                        </div>
                        <div v-else-if="form[item.name] === form.priority">
                          <v-chip
                            v-if="form.priority === 'BAIXA'"
                            color="success"
                            label
                            >{{ form.priority }}</v-chip
                          >
                          <v-chip
                            v-if="form.priority === 'MÉDIA'"
                            color="warning"
                            label
                            >{{ form.priority }}</v-chip
                          >
                          <v-chip
                            v-if="form.priority === 'ALTA'"
                            color="error"
                            label
                            >{{ form.priority }}</v-chip
                          >
                          <v-chip
                            v-if="form.priority === 'INADIÁVEL'"
                            color="black"
                            dark
                            label
                            >{{ form.priority }}</v-chip
                          >
                        </div>
                        <div v-else-if="form[item.name] === form.os_type">
                          <v-select
                            outlined
                            dense
                            v-if="service"
                            label="Tipo da OS"
                            :disabled="disable"
                            item-text="name"
                            item-value="id"
                            :items="service"
                            @change="getServiceSubItem(), changeServiceType()"
                            v-model="form.ref_type"
                          ></v-select>
                        </div>
                        <div v-else-if="form[item.name] === form.subtype_os">
                          <v-select
                            outlined
                            dense
                            label="Subtipo da OS"
                            :disabled="disable"
                            v-if="subitems"
                            item-text="name"
                            item-value="id"
                            @change="changeSubtype()"
                            :items="subitems"
                            v-model="form.subtype_id"
                            :error-messages="
                              $v.form.subtype_id.$dirty &&
                              !$v.form.subtype_id.required
                                ? ['Informe o subtipo da OS']
                                : []
                            "
                            @input="$v.form.subtype_id.$touch()"
                            @blur="$v.form.subtype_id.$touch()"
                          ></v-select>
                        </div>
                        <div v-else>
                          {{ edit[item.name] }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </v-list>
            </v-tab-item>
            <v-tab-item class="mt-5">
              <v-form>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-autocomplete
                      :items="employe"
                      v-model="form.responsible_id"
                      item-text="name"
                      item-value="ref_employe"
                      label="Técnico"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :items="employe"
                      v-model="form.analyst_id"
                      item-text="name"
                      item-value="ref_employe"
                      label="Analista"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-select
                      :items="event_status"
                      item-text="name"
                      v-model="form.event_status_id"
                      item-value="id"
                      @change="startTime()"
                      label="Situação da OS"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>

                <v-divider class="mb-5"></v-divider>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      name="name"
                      label="Inicio do evento"
                      v-facade="'##/##/#### ##:##:##'"
                      :value="format_date(form.start_date)"
                      @input="startDate($event)"
                      prepend-icon="mdi-calendar-clock"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      name="name"
                      label="Fim do evento"
                      :value="format_date(form.end_date)"
                      v-facade="'##/##/#### ##:##:##'"
                      @input="finalDate($event)"
                      prepend-icon="mdi-calendar-clock"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
            <v-tab-item class="mt-5">
              <v-form>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-select
                      :items="priority"
                      v-model="form.priority"
                      label="Prioridade"
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="8">
                    <v-select
                      :items="scheduling_status"
                      item-text="name"
                      v-model="form.scheduling_status_id"
                      item-value="id"
                      label="Status do agendamento"
                      @change="changeSchedule()"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row> </v-row>
                <v-divider class="mb-5"></v-divider>

                <v-row
                  v-if="
                    form.scheduling_status_id === 5 ||
                      form.scheduling_status_id === 6 ||
                      form.scheduling_status_id === 3
                  "
                >
                  <div class="title mb-5">
                    Verifique a disponibilidade das ofertas:
                  </div>
                  <v-col cols="4" md="8">
                    <v-select
                      label="Data de agendamento"
                      outlined
                      v-model="offerSelected"
                      return-object
                      :item-text="item => dateformat(item.data_scheduling)"
                      item-value="data_scheduling"
                      :items="offers"
                      v-if="offers"
                      required
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      label="Período"
                      outlined
                      :items="periods"
                      v-if="periods"
                      required
                      v-model="periodSelected"
                      @change="findOffer()"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" depressed @click="submit"
            >Salvar Alterações</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import DateFormat from "../../mixins/formatDate";
import { dateTime, dateFormat } from "../../utils/helpers/dateHelper";
var utc = require("dayjs/plugin/utc"); // dependent on utc plugin
var timezone = require("dayjs/plugin/timezone");
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  validations: {
    form: {
      ref_type: { required },
      subtype_id: { required }
    }
  },
  mixins: [DateFormat, validationMixin],
  props: ["dialog", "edit", "user"],

  data() {
    return {
      priority: ["ALTA", "MÉDIA", "BAIXA", "INADIÁVEL"],
      active: this.dialog,
      event_status: null,
      scheduling_status: null,
      start_date: null,
      final_date: null,
      offerSelected: null,
      periodSelected: [],
      disable: false,
      employe: null,
      updated: 1,
      offers: [],
      periods: [],
      service: [],
      subitems: [],
      form: {},
      headers: [
        { name: "protocol", value: "Protocolo" },
        { name: "client_type", value: "Tipo de Cliente" },
        { name: "client", value: "Cliente" },
        { name: "phone", value: "Telefone" },
        { name: "city", value: "Cidade" },
        { name: "district", value: "Bairro" },
        { name: "area", value: "Área" },
        { name: "client_technoloy", value: "Tecnologia" },
        { name: "os_type", value: "Tipo da OS" },
        { name: "subtype_os", value: "Subtipo da OS" },
        { name: "data_scheduling", value: "Data do agendamento" },
        { name: "period", value: "Periodo" },
        { name: "priority", value: "Prioridade" },
        { name: "event_status", value: "Situação da OS" },
        { name: "scheduling_status", value: "Status do agendamento" },
        { name: "analyst_name", value: "Analistas" },
        { name: "responsible_name", value: "Técnico" },
        { name: "requestor_name", value: "Criado Por" },
        { name: "created_at", value: "Criado em" }
      ]
    };
  },
  filters: {
    formatDateTime(value) {
      return dateTime(value);
    }
  },
  mounted() {
    this.getEventStatus();
    this.getStatus();
    this.getEmploye();
    this.loadForm();
    this.getServiceItem();
    this.getServiceSubItem();

    console.log(this.edit);
  },
  watch: {
    offerSelected: function() {
      this.getPeriods();
    }
  },
  methods: {
    changeSubtype() {
      this.subitems.map(s => {
        if (this.form.subtype_id === s.id) {
          console.log(this.form.subtype_os);
          this.form.subtype_os = s.name;
        }
      });
    },
    changeServiceType() {
      this.service.map(s => {
        if (this.form.ref_type === s.id) {
          this.form.os_type = s.name;
        }
      });
    },
    async getServiceItem() {
      const response = await this.$http.get("/service-items");
      const new_services_array = response.data.filter(d => {
        return d.name === "MANUTENÇÃO - EQUIPAMENTOS" ||
          d.name === this.form.os_type ||
          d.name === "RETIRADA DE KIT - INADIMPLENCIA"
          ? true
          : false;
      });
      this.service = new_services_array;
    },
    async getServiceSubItem() {
      try {
        if (this.form.os_type) {
          const response = await this.$http.get(
            `/service-item/${this.form.ref_type}/subitems`
          );
          this.subitems = response.data;
        }
      } catch (error) {
        this.$toast.error("Tipo de os e subtipo de os não informado!!");
      }
    },
    getEdit(val) {
      this.start_date = val;
    },
    async startTime() {
      if (this.form.event_status_id === 3) {
        if (!this.form.end_date) {
          this.stop_date();
        }
      }
      if (this.form.event_status_id === 2) {
        if (!this.form.start_date) {
          this.init_date();
        }
      }
    },
    async changeSchedule() {
      if (this.form.scheduling_status_id === 5) {
        this.getOffer();
      }
      if (this.form.scheduling_status_id === 6) {
        this.getOffer();
      }
      if (this.form.scheduling_status_id === 3) {
        this.getOffer();
      }
    },
    format_date(value) {
      if (value) {
        return dateTime(value);
      } else {
        return "";
      }
    },
    dateformat(date) {
      return dateFormat(date);
    },
    startDate(event) {
      this.form.start_date = dayjs(event, "DD/MM/YYYY HH:mm:ss").format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    finalDate(event) {
      this.form.end_date = dayjs(event, "DD/MM/YYYY HH:mm:ss").format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    async init_date() {
      this.form.start_date = dayjs()
        .tz("America/Fortaleza")
        .format("YYYY-MM-DD HH:mm:ss");
    },
    async stop_date() {
      this.form.end_date = dayjs()
        .tz("America/Fortaleza")
        .format("YYYY-MM-DD HH:mm:ss");
    },
    async getOffer() {
      const response = await this.$http.post("/offer/areas", {
        ref_type: this.form.ref_type,
        area: this.form.area_id
      });
      if (response.data.offer.length <= 0) {
        return this.$toast.error(
          "Nenhuma oferta localizada, cadastre uma nova oferta para continuar"
        );
      }
      this.offers = response.data.offer;
    },
    async findOffer() {
      const response = await this.$http.post("/offer/areas", {
        ref_type: this.form.ref_type,
        area: this.form.area_id,
        data_scheduling: this.offerSelected.data_scheduling,
        period: this.periodSelected
      });
      this.form.ref_offer = response.data.offer[0].id;
    },

    async getPeriods() {
      const response = await this.$http.post("/offer/areas", {
        ref_type: this.form.ref_type,
        area: this.form.area_id,
        data_scheduling: this.offerSelected.data_scheduling
      });
      this.periods = response.data.periods;
    },
    async submit() {
      try {
        const response = await this.$http.put("/scheduling", this.form);

        console.log(response);
        this.$emit("update");
        this.updated += 1;
        this.$toast.success("Alterado com sucesso.");
      } catch (error) {
        this.$toast.error("Erro ao alterar agendamento");
      }
    },
    async loadForm() {
      this.form = Object.assign({}, this.edit);
      this.form.user_id = this.user.id;
    },
    async getEventStatus() {
      try {
        const response = await this.$http.get("/event-status");
        this.event_status = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getStatus() {
      try {
        const response = await this.$http.get("/scheduling-status");
        this.scheduling_status = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getEmploye() {
      try {
        const response = await this.$http.get("/employe");
        this.employe = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
    }
  }
};
</script>

<style></style>
