<template>
  <div class="text-center">
    <v-card>
      <v-card-title primary-title> Filtros </v-card-title>
      <v-card-text v-if="headers_filter">
        <v-row>
          <v-col>
            <v-autocomplete
              outlined
              v-if="service"
              dense
              label="Tipo da OS"
              item-text="name"
              item-value="id"
              multiple
              flat
              chips
              clearable
              :items="service"
              @change="getServiceSubItem()"
              @click:clear="filters.service.ref_type = null"
              v-model="filters.filter['ref_type']"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 3">
                  <span>
                    {{ item.name }}
                  </span>
                </v-chip>
                <span v-if="index === 3" class="grey--text caption">
                  +({{ filters.filter["ref_type"].length - 3 }})
                </span>
              </template>
            </v-autocomplete>
            <!-- <v-select
              outlined
              v-if="service"
              dense
              label="Tipo da OS"
              item-text="name"
              item-value="id"
              :items="service"
              clearable
              @click:clear="filters.service.ref_type = null"
              @change="getServiceSubItem()"
              v-model="filters.filter['ref_type']"
            ></v-select> -->
          </v-col>
          <div v-if="filters.filter['ref_type'] > 0">
            <v-col>
              <v-select
                outlined
                label="Subtipo da OS"
                dense
                v-if="subitems && filters.filter['ref_type'].length < 2"
                item-text="name"
                :items="subitems"
                clearable
                @click:clear="filters.service.subtype_os = []"
                v-model="filters.service.subtype_os"
              ></v-select>
            </v-col>
          </div>
        </v-row>
        <div v-for="header in headers_filter_employe" :key="header.name">
          <v-autocomplete
            flat
            multiple
            dense
            chips
            clearable
            outlined
            :label="header.name"
            item-text="name"
            item-value="ref_employe"
            @click:clear="filters[header.filter]"
            :items="columnValueList(header.data)"
            v-model="filters.filter[header.filter]"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3">
                <span>
                  {{ item.name }}
                </span>
              </v-chip>
              <span v-if="index === 3" class="grey--text caption">
                +({{ filters.filter[header.filter].length - 3 }})
              </span>
            </template>
          </v-autocomplete>
        </div>
        <div v-for="header in headers_filter" :key="header.name">
          <v-autocomplete
            flat
            multiple
            dense
            chips
            clearable
            outlined
            :label="header.name"
            item-text="name"
            item-value="id"
            @click:clear="filters[header.filter]"
            :items="columnValueList(header.data)"
            v-model="filters.filter[header.filter]"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3">
                <span>
                  {{ item.name }}
                </span>
              </v-chip>
              <span v-if="index === 3" class="grey--text caption">
                (+{{ filters.filter[header.filter].length - 3 }})
              </span>
            </template>
          </v-autocomplete>
        </div>
        <v-row>
          <v-col>
            <v-menu
              v-model="from"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  required
                  outlined
                  label="Agendado para"
                  hint="dia/mês/ano"
                  persistent-hint
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  dense
                  v-facade="'##/##/####'"
                  v-on="on"
                  clearable
                  @click:clear="filters.date_scheduling.from = null"
                  :value="formatDateFilter(filters.date_scheduling.from)"
                  autocomplete="off"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.date_scheduling.from"
                no-title
                @input="from = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              v-model="to"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  required
                  outlined
                  dense
                  label="Até"
                  hint="dia/mês/ano"
                  persistent-hint
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  v-facade="'##/##/####'"
                  v-on="on"
                  :value="formatDateFilter(filters.date_scheduling.to)"
                  autocomplete="off"
                  clearable
                  @click:clear="filters.date_scheduling.to = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.date_scheduling.to"
                no-title
                @input="to = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="clearFilters()"> Limpar </v-btn>
        <v-btn color="primary" text @click="apply()"> Aplicar </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import formatDate from "../../mixins/formatDate";
import { dateFormat } from "../../utils/helpers/dateHelper";

export default {
  mixins: [formatDate],

  data() {
    return {
      fav: true,
      message: false,
      hints: true,
      from: false,
      to: false,
      service: [],
      subitems: [],

      items: {
        employes: [],
        city: [
          { name: "Abaiara" },
          { name: "Brejo Santo" },
          { name: "Mauriti" },
          { name: "Milagres" },
          { name: "Missão Velha" },
          { name: "Penaforte" },
          { name: "Barbalha" },
          { name: "Crato" },
          { name: "Juazeiro do Norte" }
        ],
        event_status: [],
        scheduling_status: [],
        period: [{ name: "Manhã" }, { name: "Tarde" }, { name: "Noite" }],
        priority: [
          { name: "ALTA" },
          { name: "BAIXA" },
          { name: "MÉDIA" },
          { name: "INADIÁVEL" }
        ],
        client_type: [
          { name: "B2B " },
          { name: "B2B ESPECIAL" },
          { name: "B2C" },
          { name: "B2G" },
          { name: "CLIENTE INTERNO" },
          { name: "CONDOMÍNIO" },
          { name: "TEMPORÁRIO" }
        ]
      },
      filters: {
        filter: {},
        service: {
          ref_type: null,
          subtype_os: null
        },
        date_scheduling: {
          from: null,
          to: null
        }
      },
      headers_filter_employe: [
        { name: "Analista", data: "employes", filter: "analyst_id" },
        { name: "Técnico", data: "employes", filter: "responsible_id" },
        { name: "Aberto por", data: "employes", filter: "requestor_id" }
      ],
      headers_filter: [
        { name: "Prioridade", data: "priority", filter: "priority" },

        { name: "Periodo", data: "period", filter: "period" },

        {
          name: "Situação da os",
          data: "event_status",
          filter: "event_status_id"
        },
        {
          name: "Status do agendamento",
          data: "scheduling_status",
          filter: "scheduling_status_id"
        },
        { name: "Tipo de Cliente", data: "client_type", filter: "client_type" },
        { name: "Cidade", data: "city", filter: "city" }
      ]
    };
  },

  mounted() {
    this.getEventStatus();
    this.getSchedulingStatus();
    this.getEmployer();
    this.getServiceItem();
    if (localStorage.getItem("filters") !== null) {
      this.filter_enable = true;
      let criterias = JSON.parse(window.localStorage.getItem("filters"));

      this.filters = Object.assign({}, criterias);

      console.log(this.filters);
    }
  },

  methods: {
    async getEventStatus() {
      const response = await this.$http.get("/event-status");
      this.items.event_status = response.data;
    },
    formatDateFilter(date) {
      if (date) return dateFormat(date);
    },
    async getEmployer() {
      const response = await this.$http.get("/employe");
      this.items.employes = response.data;
    },

    async getSchedulingStatus() {
      const response = await this.$http.get("/scheduling-status");
      this.items.scheduling_status = response.data;
    },
    columnValueList(val) {
      return this.items[val];
    },
    async clearFilters() {
      this.filters = Object.assign(
        {},
        {
          filter: {},
          service: {
            ref_type: null,
            subtype_os: null
          },
          date_scheduling: {
            from: null,
            to: null
          }
        }
      );

      localStorage.removeItem("filters");
      this.$emit("update-badge", false);
      this.$emit("update");
    },

    async getServiceItem() {
      const response = await this.$http.get("/service-items");
      this.service = response.data;
    },
    async getServiceSubItem() {
      try {
        if (this.filters.filter["ref_type"].length < 2) {
          if (this.filters.filter["ref_type"][0]) {
            const response = await this.$http.get(
              `/service-item/${this.filters.filter["ref_type"][0]}/subitems`
            );
            this.subitems = response.data;
          }
        } else {
          delete this.filters.service.subtype_os;
        }
      } catch (error) {
        this.$toast.error("Tipo de os e subtipo de os não informado!!");
      }
    },
    async apply() {
      let criteria = {
        filter: this.filters.filter,
        date_scheduling: this.filters.date_scheduling,
        service: this.filters.service
      };

      const response = await this.$http.post("/scheduling/filter", criteria);

      this.$emit("update-table", response.data);
      this.$emit("update-badge", true);

      localStorage.setItem("filters", JSON.stringify(criteria));
    }
  }
};
</script>

<style></style>
