var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.profile)?_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-toolbar',{staticClass:"teal",attrs:{"dense":"","flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("Agendamentos")])],1),(_vm.schedulings)?_c('v-card-title',{staticClass:"mb-n5 mt-n3 grey lighten-5"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":800,"right":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""}},'v-btn',attrs,false),on),[(_vm.filter_enable)?_c('v-badge',{attrs:{"dot":"","overlap":"","color":"red"}},[_c('v-icon',[_vm._v("mdi-filter")])],1):_c('v-icon',[_vm._v("mdi-filter")])],1)]}}],null,false,3554622152),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('filterScheduling',{attrs:{"menu":_vm.filter_scheduling},on:{"update-table":_vm.updateTable,"update":function($event){return _vm.schedulingFilter()},"update-badge":_vm.filter_badge}})],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),(_vm.schedulings)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.schedulings,"show-expand":"","search":_vm.search},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header,i){return {key:("header." + (header.value)),fn:function(ref){
var header = ref.header;
return [(!header.in_expanded)?_c('span',{key:i},[_vm._v(" "+_vm._s(header.in_expanded)+" "+_vm._s(header.text)+" ")]):_vm._e()]}}}),{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"row no-gutters"},[_c('v-list',{staticClass:"transparent",attrs:{"width":"700"}},_vm._l((_vm.header_expanded),function(i,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('strong',[_vm._v(_vm._s(i.text)+": ")])]),(i.value === 'data_scheduling')?_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item[i.value]))+" ")]):_vm._e(),(i.value === 'start_date' || i.value === 'end_date')?_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item[i.value]))+" ")]):_c('v-list-item-content',[_vm._v(" "+_vm._s(item[i.value])+" ")])],1)}),1)],1)])]}},{key:"item.protocol",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.getReports(item.protocol)}}},[_vm._v(_vm._s(item.protocol)+" "),_c('v-icon',{attrs:{"right":"","color":"primary"}},[_vm._v("mdi-message-text-outline")])],1)]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","dark":"","color":_vm.priorityColor(item.priority)}},[(item.unavoidable)?_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-alert-circle")]):_vm._e(),_vm._v(" "+_vm._s(item.priority)+" ")],1)]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.colorPeriod(item.period),"label":"","small":"","dark":""}},[_vm._v(_vm._s(item.period))])]}},{key:"item.event_status",fn:function(ref){
var item = ref.item;
return [(!item.event_status)?_c('div',[_c('v-chip',[_vm._v("NÃO INICIADO")])],1):_c('div',[(item.event_status_id !== 2)?_c('strong',{style:({ color: _vm.eventColor(item.event_status_id) })},[_vm._v(" "+_vm._s(item.event_status)+" ")]):_c('div',[_c('strong',{style:({ color: _vm.eventColor(item.event_status_id) })},[_vm._v(" "+_vm._s(item.event_status)+" ")]),_c('v-chip',{attrs:{"label":"","color":"grey lighten-5"}},[_c('div',[_vm._v(" Iniciado há "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.diff_time))])])])],1)])]}},{key:"item.data_scheduling",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_scheduling))+" ")]}},{key:"item.scheduling_status",fn:function(ref){
var item = ref.item;
return [(!item.scheduling_status)?_c('div',[_c('v-chip',[_vm._v("Não Iniciado")])],1):_c('div',[_vm._v(" "+_vm._s(item.scheduling_status)+" ")])]}},{key:"item.responsible_name",fn:function(ref){
var item = ref.item;
return [(!item.responsible_name)?_c('div',[_c('v-chip',[_vm._v("Não vinculado")])],1):_c('div',[_vm._v(" "+_vm._s(item.responsible_name)+" ")])]}},{key:"item.analyst_name",fn:function(ref){
var item = ref.item;
return [(!item.analyst_name)?_c('div',[_c('v-chip',[_vm._v("Não vinculado")])],1):_c('div',[_vm._v(" "+_vm._s(item.analyst_name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{staticClass:"colorLink",attrs:{"icon":"","disabled":!_vm.canEdit}},[_c('v-icon',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-circle-edit-outline")])],1),(_vm.isAdmin || _vm.canRemove)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.remove(item)}}},[_vm._v("mdi-delete")])],1):_vm._e()],1)]}}],null,true)}):_vm._e()],1),(_vm.active_dialog)?_c('dialogReports',{attrs:{"dialog":_vm.active_dialog,"reports":_vm.reports},on:{"close-dialog":function($event){_vm.active_dialog = !_vm.active_dialog}}}):_vm._e(),(_vm.edit_dialog)?_c('EditScheduling',{attrs:{"user":_vm.profile,"dialog":_vm.edit_dialog,"edit":_vm.edit_form},on:{"update":function($event){return _vm.schedulingFilter()},"close-dialog":function($event){_vm.edit_dialog = !_vm.edit_dialog}}}):_vm._e(),(_vm.removeDialog)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c('removeScheduling',{attrs:{"remove_scheduling":_vm.scheduling_remove},on:{"update":function($event){return _vm.schedulingFilter()},"close-dialog":function($event){_vm.removeDialog = !_vm.removeDialog}}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }