<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      max-width="800"
    >
      <v-card>
        <v-toolbar max-height="60px" dark color="teal">
          <v-toolbar-title>Relatos da solicitação</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-card
            color="grey lighten-4"
            v-for="(r, i) in reports"
            :key="i"
            class="mb-5"
            elevation="1"
            outlined
          >
            <v-card-title class="primary-title">
              <v-icon left color="primary">mdi-message-text</v-icon>
              {{ r.title }}
              <v-spacer></v-spacer>
              <v-card-subtitle class="text--primary">
                <v-icon left>mdi-calendar-clock</v-icon>
                {{ r.created | formatDate }}</v-card-subtitle
              >
            </v-card-title>

            <v-card-text>
              <div class="text--primary" v-html="r.description"></div>

              <v-divider class="mb-3"></v-divider>
              <div class="text--primary ">
                <v-icon>mdi-comment-account</v-icon>
                {{ r.employe }}
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                depressed
                class="ma-2"
                v-bind="attrs"
                v-on="on"
                :href="link"
                target="_blank"
              >
                Abrir no Synsuite
              </v-btn>
            </template>
            Ver relatos no synsuite
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { dateTime } from "../../utils/helpers/dateHelper";
export default {
  props: ["dialog", "reports"],

  created() {
    this.link = `https://erp.linkcariri.com.br/solicitation_treatments/index/${this.reports[0].assignment_id}`;
  },
  data() {
    return {
      link: "",
      dialog_synsuite: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    }
  },
  filters: {
    formatDate(value) {
      return dateTime(value);
    }
  }
};
</script>
<style scoped></style>
